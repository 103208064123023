import React, { ReactNode } from 'react';
import styled from '@emotion/styled';
import { Gray_Light_White, web_max_width } from 'styles';

export interface AuthMainProps {
  children?: ReactNode;
  className?: string;
}

export default function AuthMain({ children, className }: AuthMainProps) {
  return <Main className={className}>{children}</Main>;
}

const Main = styled.main`
  margin: auto;
  /** 삼성 브라우저 지원을 위해 풀어작성했습니다. 순서를 변경하지 말아주세요. */
  padding: 52px 16px 48px;
  padding-bottom: calc(env(safe-area-inset-bottom) + 48px);
  padding-bottom: calc(constant(safe-area-inset-bottom) + 48px);
  background: ${Gray_Light_White};
  max-width: ${web_max_width}px;
`;
