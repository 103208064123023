import React, { FC, HTMLAttributes } from 'react';
import { vars } from '@croquiscom-pvt/zds/dist/tokens';
import styled from '@emotion/styled';
import {
  Gray_Light_100,
  Gray_Light_150,
  Gray_Light_300,
  Gray_Light_400,
  Gray_Light_700,
  Typography,
  web_max_width,
} from 'styles';

const BrowserHref = {
  business_info: 'https://www.ftc.go.kr/bizCommPop.do?wrkr_no=2148891525',
  service_terms: 'https://cf.res.s.zigzag.kr/terms/user/service.html',
  privacy: 'https://cf.res.s.zigzag.kr/terms/user/privacy.html',
};

const WebFooter: FC<HTMLAttributes<HTMLElement>> = ({ className }) => {
  return (
    <Footer className={className}>
      <Wrapper>
        <Title className={Typography.BODY_14_BOLD}>(주)카카오스타일 사업자 정보</Title>
        <Info className={Typography.CAPTION_12_REGULAR}>
          <li>
            <Label>대표이사</Label>
            <Description>서정훈</Description>
          </li>
          <li>
            <Label>주소</Label>
            <Description>경기 성남시 분당구 판교역로 235, H스퀘어 N동 6층</Description>
          </li>
          <li>
            <Label>문의전화</Label>
            <Description>02-1670-8050</Description>
          </li>
          <li>
            <Label>이메일</Label>
            <Description>info@kakaostyle.com</Description>
          </li>
          <li>
            <Label>사업자등록번호</Label>
            <Description>214-88-91525</Description>
          </li>
          <li>
            <Label>통신판매업 신고번호</Label>
            <Description>2024-성남분당A-0172</Description>
          </li>
          <li>
            <Label>호스팅 사업자</Label>
            <Description>(주)카카오스타일</Description>
          </li>
        </Info>
        <List className={Typography.BODY_13_REGULAR}>
          <ListItem>
            <a href={BrowserHref.business_info} target='_blank' rel='noreferrer'>
              사업자 정보 조회
            </a>
          </ListItem>
          <ListItem>
            <a href={BrowserHref.service_terms} target='_blank' rel='noreferrer'>
              이용약관
            </a>
          </ListItem>
          <ListItem>
            <a href={BrowserHref.privacy} target='_blank' rel='noreferrer'>
              개인정보처리방침
            </a>
          </ListItem>
        </List>
        <Paragraph className={Typography.CAPTION_12_REGULAR}>
          ㈜카카오스타일은 통신판매중개자로서 통신판매의 당사자가 아닙니다.
          <br />
          따라서, 등록된 상품, 거래정보 및 거래에 대하여 ㈜카카오스타일은
          <br />
          어떠한 책임도 지지 않습니다.
          <br />
          <br />
          Copyright © kakaostyle Corp. All rights reserved.
        </Paragraph>
      </Wrapper>
    </Footer>
  );
};
export default WebFooter;

const Footer = styled.footer`
  background: ${Gray_Light_100};
`;

const Wrapper = styled.div`
  max-width: ${web_max_width}px;
  padding: 30px 16px 72px;
  padding-bottom: calc(env(safe-area-inset-bottom) + 68px);
  padding-bottom: calc(constant(safe-area-inset-bottom) + 68px);
  margin: auto;
`;

const Title = styled.h2`
  color: ${vars.color.textSecondary};
  margin-bottom: 16px;
`;

const Info = styled.ul`
  color: ${vars.color.textTertiary};
  padding-bottom: 16px;
  margin: 0 0 16px 0;
  border-bottom: 1px solid ${Gray_Light_150};
  &::after {
    content: '';
    display: block;
    clear: both;
  }
  li {
    display: flex;
    margin-bottom: 4px;
  }
`;

const Label = styled.span`
  white-space: pre-wrap;
`;

const Description = styled.p`
  margin-left: 12px;
`;

const List = styled.ul`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 16px;
`;

const ListItem = styled.li`
  color: ${vars.color.textTertiary};

  &:last-of-type {
    color: ${vars.color.textPrimary};
  }

  &:not(:last-of-type)::after {
    content: '';
    display: inline-block;
    width: 2px;
    height: 13px;
    background: ${Gray_Light_300};
    margin: 0 8px;
    vertical-align: middle;
  }
`;

const Paragraph = styled.p`
  color: ${vars.color.textTertiary};
  white-space: pre-wrap;
`;
