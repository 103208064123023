export const IllustWarning = ({ width = 81, height = 80, ...props }: JSX.IntrinsicElements['svg']) => (
  <svg width={width} height={height} viewBox='0 0 81 80' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <g clipPath='url(#clip0_3056_23857)'>
      <path
        d='M36.9961 16.9935L12.0847 58.801C11.7141 59.4228 11.5145 60.132 11.5061 60.8564C11.4978 61.5808 11.681 62.2945 12.0372 62.9247C12.3933 63.5548 12.9097 64.0789 13.5335 64.4435C14.1573 64.8081 14.8664 65.0001 15.5883 65H65.4124C66.1342 64.9999 66.843 64.8077 67.4667 64.443C68.0903 64.0783 68.6064 63.5542 68.9624 62.9241C69.3184 62.2939 69.5016 61.5804 69.4932 60.8561C69.4848 60.1318 69.2851 59.4227 68.9146 58.801L44.0032 16.9935C43.641 16.3854 43.1277 15.8819 42.5137 15.5324C41.8996 15.1828 41.2057 14.999 40.4996 14.999C39.7936 14.999 39.0997 15.1828 38.4856 15.5324C37.8716 15.8819 37.3583 16.3854 36.9961 16.9935Z'
        fill='url(#paint0_linear_3056_23857)'
      />
      <path
        d='M41.1318 57.4273C42.7902 57.4273 44.1346 56.0781 44.1346 54.4139C44.1346 52.7496 42.7902 51.4004 41.1318 51.4004C39.4733 51.4004 38.1289 52.7496 38.1289 54.4139C38.1289 56.0781 39.4733 57.4273 41.1318 57.4273Z'
        fill='#666666'
      />
      <path
        d='M43.9496 33.5245C43.8875 33.4591 43.8127 33.4071 43.7299 33.3717C43.6471 33.3362 43.558 33.3181 43.4679 33.3184H38.797C38.7093 33.3182 38.6225 33.3354 38.5414 33.3689C38.4604 33.4024 38.3867 33.4517 38.3247 33.5138C38.2626 33.576 38.2134 33.6498 38.1798 33.7311C38.1462 33.8123 38.1289 33.8994 38.1289 33.9874V34.0161L38.797 49.4207C38.8043 49.5931 38.8778 49.756 39.0021 49.8753C39.1263 49.9947 39.2917 50.0612 39.4637 50.061H42.7999C42.972 50.0612 43.1375 49.9947 43.262 49.8754C43.3864 49.7561 43.4602 49.5932 43.4679 49.4207L44.1346 34.0161C44.1387 33.9258 44.1244 33.8357 44.0925 33.7512C44.0607 33.6667 44.0121 33.5896 43.9496 33.5245Z'
        fill='#666666'
      />
    </g>
    <defs>
      <linearGradient
        id='paint0_linear_3056_23857'
        x1='40.4996'
        y1='88.8637'
        x2='40.4996'
        y2='15'
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#BBBBBB' />
        <stop offset='1' stopColor='#E8E8E8' />
      </linearGradient>
      <clipPath id='clip0_3056_23857'>
        <rect width='58' height='50' fill='white' transform='translate(11.5 15)' />
      </clipPath>
    </defs>
  </svg>
);
