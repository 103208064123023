import { useEffect } from 'react';
import { useRouter } from 'next/router';

import { Toast } from '@common/overlay-manager';

import { message } from './messege';
import { isMessageKey, MESSAGE_QUERY_NAME, removeMessageQueryFromPath } from './utils';

export function useMessenger() {
  const router = useRouter();

  useEffect(() => {
    const messageQuery = router.query[MESSAGE_QUERY_NAME];
    if (isMessageKey(messageQuery)) {
      Toast.show(message[messageQuery].value);
      const urlWithoutMessage = removeMessageQueryFromPath(router.asPath);
      router.replace(urlWithoutMessage, undefined, { shallow: true });
    }
  }, [router]);
}
