import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import {
  Gray_Light_100,
  Gray_Light_200,
  Gray_Light_400,
  Gray_Light_700,
  Gray_Light_800,
  light_theme_colors,
  Static_White,
} from '@croquiscom/zds';
import { Text } from '@croquiscom-pvt/zds';
import { vars } from '@croquiscom-pvt/zds/dist/tokens';
import styled from '@emotion/styled';
import {
  apple_social_icon,
  facebook_social_icon,
  google_social_icon,
  kakaotalk_logo,
  mail_logo,
  phone_icon,
  toss_social_icon,
} from 'components/auth/common/icons';
import { zigzag_logo } from 'components/auth/common/images';
import AuthLink from 'components/auth/common/Link';
import AuthMain from 'components/auth/common/Main';
import AuthNavigation from 'components/auth/common/Navigation';
import {
  useAppleLogin,
  useFacebookLogin,
  useGoogleLogin,
  useKakaoLogin,
  useTossLogin,
} from 'components/auth/hooks/login';
import Footer from 'components/naver-shopping/common/Footer';
import { useRouter } from 'next/router';
import { metadataQueryOption } from 'queries/metadata';
import { Typography } from 'styles';

import { useUBL } from '@common/log-manager';
import { useLandingPopup } from '@common/overlay-manager';
import { HOME_LANDING_LINK } from '@common/overlay-manager';
import { AuthSessionStorage } from '@domains/auth-common';

import { AlreadyLogin } from './alreadyLogin';

import { web_path } from 'constants/web_path';

interface Props {
  isAlreadyLoggedIn?: boolean;
}
export default function AuthLoginMain({ isAlreadyLoggedIn }: Props) {
  const [vh, setVh] = useState(0);
  const { query, push } = useRouter();
  const tracker = useUBL();
  const { loginWithKakao } = useKakaoLogin();
  const { loginWithFacebook } = useFacebookLogin();
  const { loginWithApple } = useAppleLogin();
  const { loginWithToss } = useTossLogin();
  const { containerRef: googleLoginButtonRef, setContainerRef: setGoogleLoginButtonRef } = useGoogleLogin({
    type: 'icon',
    size: 'large',
    click_listener: () => {
      tracker({
        category: 'click',
        navigation: 'login',
        object_type: 'button',
        object_id: 'google',
      });
      AuthSessionStorage.saveRedirectUrl(redirect_url);
    },
  });
  const { open: openLandingPopup } = useLandingPopup(HOME_LANDING_LINK.KR);

  const has_redirect_url = typeof query.redirect === 'string';
  const redirect_url = has_redirect_url ? (query.redirect as string) : web_path.root;
  const forceShowlogin = typeof query.forceShowLogin === 'string' && query.forceShowLogin === 'true';
  const { t } = useTranslation('auth');

  const { data: metadata } = useQuery(metadataQueryOption());

  useEffect(() => {
    const vh = window.innerHeight;
    setVh(vh);
  }, []);

  const social_login = useMemo(
    () => ({
      buttons: [
        {
          type: 'kakao',
          src: kakaotalk_logo,
          alt: '카카오 로그인 로고',
          text: '카카오로 3초 만에 시작하기',
          background_color: light_theme_colors.Kakao_Yellow,
          text_color: vars.color.textPrimary,
          onClick: () => {
            tracker({
              category: 'click',
              navigation: 'login',
              object_type: 'button',
              object_id: 'kakao',
            });
            loginWithKakao(redirect_url);
          },
        },
        {
          type: 'phone',
          src: phone_icon,
          alt: '휴대폰 번호로 로고',
          text: '휴대폰 번호로 로그인',
          background_color: vars.color.fillPrimaryInverse,
          text_color: vars.color.textStrongInverse,
          onClick: () => {
            tracker({
              category: 'click',
              navigation: 'login',
              object_type: 'button',
              object_id: 'login_tel_number',
            });
            const url = `${web_path.auth.tel_login}${
              has_redirect_url ? `?redirect=${encodeURIComponent(redirect_url)}` : ''
            }`;
            push(url);
          },
        },
        {
          type: 'email',
          src: mail_logo,
          alt: '이메일 로그인하기 로고',
          text: '이메일로 로그인',
          border_color: vars.color.borderPrimary,
          text_color: vars.color.textPrimary,
          onClick: () => {
            tracker({
              category: 'click',
              navigation: 'login',
              object_type: 'button',
              object_id: 'email',
            });
            const url = `${web_path.auth.email_login}${
              has_redirect_url
                ? `?redirect=${encodeURIComponent(redirect_url)}${forceShowlogin ? '&forceShowLogin=true' : ''}`
                : ''
            }`;
            push(url);
          },
        },
      ],
      icons: [
        {
          type: 'apple',
          src: apple_social_icon,
          alt: '애플 소셜로그인 아이콘',
          onClick: () => {
            tracker({
              category: 'click',
              navigation: 'login',
              object_type: 'button',
              object_id: 'apple',
            });
            loginWithApple(redirect_url);
          },
        },
        {
          type: 'google',
          src: google_social_icon,
          alt: '구글 로그인 로고',
          text: 'Google로 계속하기',
          border_color: Gray_Light_800,
          onClick: () => {},
        },
        {
          type: 'facebook',
          src: facebook_social_icon,
          alt: '페이스북 소셜로그인 아이콘',
          onClick: () => {
            tracker({
              category: 'click',
              navigation: 'login',
              object_type: 'button',
              object_id: 'facebook',
            });
            loginWithFacebook(redirect_url);
          },
        },
        {
          type: 'toss',
          src: toss_social_icon,
          alt: '토스 소셜로그인 아이콘',
          onClick: () => {
            tracker({
              category: 'click',
              navigation: 'login',
              object_type: 'button',
              object_id: 'toss',
            });
            loginWithToss(redirect_url);
          },
        },
      ],
    }),
    [googleLoginButtonRef],
  );

  const handleSignUp = () => {
    tracker({
      category: 'click',
      navigation: 'login',
      object_type: 'button',
      object_id: 'signup',
    });
    AuthSessionStorage.saveRedirectUrl(redirect_url);
    AuthSessionStorage.saveAuthInfo({ type: 'email' });
  };

  const renderSocialLoginButtons = () =>
    social_login.buttons.map(({ src, type, alt, text, background_color, border_color, onClick, text_color }) => {
      if (type === 'google') {
        return (
          <LoginButton
            type='button'
            key={type}
            onClickCapture={onClick}
            background_color={background_color}
            border_color={border_color}
            text_color={text_color}
            className={Typography.BODY_15_BOLD}
          >
            <GoogleLoginButton ref={setGoogleLoginButtonRef} />
            <img src={src} alt={alt} />
            <span>{text}</span>
          </LoginButton>
        );
      }
      return (
        <LoginButton
          key={type}
          onClick={onClick}
          background_color={background_color}
          border_color={border_color}
          text_color={text_color}
          className={Typography.BODY_15_BOLD}
        >
          <img src={src} alt={alt} />
          <span>{text}</span>
        </LoginButton>
      );
    });

  const renderSocialLoginIcons = () =>
    social_login.icons.map(({ src, type, alt, onClick }) => {
      if (type === 'google') {
        return (
          <SocialIconButton
            type='button'
            key={type}
            onClickCapture={() => {
              AuthSessionStorage.saveRedirectUrl(redirect_url);
              onClick();
            }}
          >
            <GoogleLoginButton ref={setGoogleLoginButtonRef} />
            <img src={src} alt={alt} />
          </SocialIconButton>
        );
      }
      return (
        <SocialIconButton type='button' onClick={onClick} key={type}>
          <img src={src} alt={alt} width={45} />
        </SocialIconButton>
      );
    });

  return (
    <>
      <AuthNavigation title={t('로그인')} />
      {isAlreadyLoggedIn ? (
        <AlreadyLogin />
      ) : (
        <>
          <Main vh={vh}>
            <LogoSection>
              <Logo src={zigzag_logo} alt='지그재그 로고 이미지' />
            </LogoSection>
            <SocialLoginSection>
              {metadata?.signup_event_message.login_page && (
                <SignupMessage
                  className={Typography.BODY_13_MEDIUM}
                  dangerouslySetInnerHTML={{ __html: metadata.signup_event_message.login_page }}
                />
              )}
              {renderSocialLoginButtons()}
              <Hr className={Typography.BODY_13_REGULAR} />
              <SocialIconList>{renderSocialLoginIcons()}</SocialIconList>
            </SocialLoginSection>
            <LinkSection>
              <AuthLink
                href={web_path.auth.terms_agreement}
                text_color={vars.color.textSecondary}
                className={Typography.BODY_13_MEDIUM}
                onClick={handleSignUp}
              >
                회원가입
              </AuthLink>
              <Line />
              <AuthLink
                href={`${web_path.auth.find_password}?redirect=${encodeURIComponent(redirect_url)}`}
                text_color={vars.color.textSecondary}
                className={Typography.BODY_13_MEDIUM}
                onClick={() => {
                  tracker({
                    category: 'click',
                    navigation: 'login',
                    object_type: 'button',
                    object_id: 'find_password',
                  });
                }}
              >
                비밀번호 재설정
              </AuthLink>
              <Line />
              <AuthLink
                href={`${web_path.auth.find_email}?redirect=${encodeURIComponent(redirect_url)}&type=FindAccount`}
                text_color={vars.color.textSecondary}
                className={Typography.BODY_13_MEDIUM}
                onClick={() => {
                  tracker({
                    category: 'click',
                    navigation: 'login',
                    object_type: 'button',
                    object_id: 'find_email',
                  });
                }}
              >
                계정 찾기
              </AuthLink>
              <Line />
              <button
                type='button'
                onClick={() => {
                  tracker({
                    category: 'click',
                    navigation: 'login',
                    object_type: 'button',
                    object_id: 'contact',
                  });
                  openLandingPopup();
                }}
              >
                <Text variant='Body_13_Medium' color='textSecondary'>
                  문의하기
                </Text>
              </button>
            </LinkSection>
          </Main>
          <Footer />
        </>
      )}
    </>
  );
}

const Main = styled(AuthMain)<{ vh: number }>`
  padding: 0 16px;
  margin: 0px 0 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: ${({ vh }) => `calc(${vh === 0 ? '100vh' : `${vh}px`} - 150px)`};
`;

const LogoSection = styled.section`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 0 1 auto;
  padding-bottom: 30px;
`;

const Logo = styled.img`
  width: auto;
  height: 36px;
`;

const SocialLoginSection = styled.section`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  flex: 0 1 auto;
  padding: 0 20px;
`;

const SignupMessage = styled.div`
  border-radius: 12px;
  padding: 12px 16px;
  margin: 32px 16px 27px;
  background: ${Static_White};
  filter: drop-shadow(0px 8px 10px rgba(0, 0, 0, 0.1));
  line-height: 18.2px;
  text-align: center;
  position: relative;
  z-index: 1;

  &::after {
    left: 50%;
    content: '';
    width: 11px;
    height: 11px;
    position: absolute;
    bottom: -5px;
    left: calc(50% - 8px);
    background: ${Static_White};
    clip-path: polygon(0% 0%, 100% 100%, 0% 100%);
    transform: rotate(-45deg);
    border-radius: 0 0 0 0.25em;
  }
`;

const SocialIconList = styled.div`
  display: flex;
  gap: 10px;
  margin-bottom: 24px;
`;

const SocialIconButton = styled.button`
  width: 45px;
  height: 46px;
  position: relative;
  margin: 4.5px 0;
  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`;

const LoginButton = styled.button<{ background_color?: string; border_color?: string; text_color?: string }>`
  width: 100%;
  max-width: 340px;
  margin: 0 32px;
  padding: 12px 40px 12px 16px;
  display: flex;
  background-color: ${({ background_color }) => background_color ?? Static_White};
  ${({ border_color }) => border_color && `border: 1px solid ${border_color}`};
  ${({ text_color }) => text_color && `color: ${text_color}`};
  border-radius: 24px;
  align-items: center;
  margin-bottom: 10px;
  position: relative;
  overflow: hidden;
  &:last-of-type {
    margin-bottom: 12px;
  }
  img {
    width: 24px;
    height: 24px;
  }
  span {
    display: inline-block;
    width: 100%;
    text-align: center;
  }
`;

const GoogleLoginButton = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 4px;
  left: 0;
  z-index: 1;
  opacity: 0.0001;
  overflow: hidden;
`;

const LinkSection = styled.section`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex: 0 0 auto;
  padding-bottom: 32px;

  button {
    color: ${Gray_Light_700};
  }
`;

const Line = styled.hr`
  width: 1px;
  height: 13px;
  margin: 0 14px;
  background: ${Gray_Light_200};
  border: none;
`;

const Hr = styled.hr`
  height: 1px;
  background: ${Gray_Light_100};
  border: none;
  width: 229px;
  margin-top: 20px;
  margin-bottom: 15px;
  position: relative;
  font-size: 12px;
  line-height: 14px;

  &::after {
    content: '또는';
    display: inline-block;
    padding: 0 10px;
    background: ${Static_White};
    position: absolute;
    left: 50%;
    transform: translate(-50%, -50%);
    color: ${Gray_Light_400};
  }
`;
