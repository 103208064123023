import { useRouter } from 'next/router';

import { convertToPageIdFromQuery, GlobalNavigationContainer, GNBConfigProvider } from '@widgets/global-navigation-bar';

import { web_path } from 'constants/web_path';

const NaverShoppingBottomBar = () => {
  const { query } = useRouter();
  const page_id = convertToPageIdFromQuery(query);
  return (
    <GNBConfigProvider
      path={{
        auth: { root: '/auth' },
        home: '/home',
        orders: '/orders',
        root: '/',
        cart: '/cart',
        categories: {
          root: '/categories',
        },
        my_page: {
          root: '/my-page',
        },
        picks: {
          root: '/picks',
        },
        my_review: {
          list: web_path.my_review_list,
          detail: web_path.review_detail,
          completed: web_path.review_completed,
        },
      }}
      page_id={page_id}
    >
      <GlobalNavigationContainer />
    </GNBConfigProvider>
  );
};
export default NaverShoppingBottomBar;
