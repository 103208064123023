import { useTranslation } from 'react-i18next';
import { vars } from '@croquiscom-pvt/zds/dist/tokens';
import styled from '@emotion/styled';
import { already_login } from 'components/auth/common/icons';

import { Gray_Light_400, Typography } from '@common/styles';
import { RoundFilledButton } from '@common/toolbox';

import { web_path } from 'constants/web_path';

export const AlreadyLogin: React.FC = () => {
  const { t } = useTranslation('auth');
  return (
    <Container>
      <img alt={'already login'} src={already_login} width={'90px'} height={'90px'} />
      <Title className={Typography.HEAD_24_BOLD}>{t('이미 로그인이 되어 있어요')}</Title>
      <SubTitle className={Typography.BODY_15_MEDIUM}>{t('홈으로 이동하거나 뒤로가기를 눌러주세요.')}</SubTitle>
      <RoundFilledButton
        onClick={() => {
          window.location.replace(web_path.home);
        }}
      >
        {t('홈으로 이동')}
      </RoundFilledButton>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0px 0px 40px 0px;
  height: calc(100vh - 100px);
  max-width: 600px;
`;

const Title = styled.span`
  margin-top: 20px;
`;
const SubTitle = styled.span`
  margin: 10px 0px 20px 0px;
  color: ${vars.color.textSecondary};
`;
