import React from 'react';
import { css } from '@emotion/react';
import { Gray_Light_900 } from 'styles';

const GeneralPagebackIcon = ({
  width = 16,
  height = 24,
  color = Gray_Light_900,
  ...props
}: JSX.IntrinsicElements['svg']) => (
  <svg
    css={css`
      cursor: pointer;
    `}
    width={width}
    height={height}
    color={color}
    viewBox='0 0 16 24'
    {...props}
  >
    <path
      d='M4.83019 11.7075L12.133 19L10.7197 20.4151L2 11.7075L10.7197 3L12.133 4.4151L4.83019 11.7075Z'
      fill='currentColor'
    />
  </svg>
);
export default GeneralPagebackIcon;
