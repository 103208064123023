import React from 'react';
import styled from '@emotion/styled';
import GeneralPagebackIcon from 'components/svg/general-pageback';
import BaseNavigationBar, { BaseNavigationBarProps } from 'components/toolbox/croquis/NavigationBar/BaseNavigation';
import { useRouter } from 'next/router';
import { Gray_Light_900, Typography } from 'styles';
import { web_max_width } from 'styles';

export interface AuthNavigationProps extends Omit<BaseNavigationBarProps, 'left'> {}
export default function AuthNavigation(props: AuthNavigationProps) {
  const router = useRouter?.();
  const back = router?.back;
  return (
    <MainNavigationStyled
      left={
        <Button aria-label='뒤로 가기' onClick={back}>
          <GeneralPagebackIcon />
        </Button>
      }
      center={props.center ?? <Title className={Typography.BODY_17_BOLD}>{props.title}</Title>}
      {...props}
    />
  );
}

const Button = styled.button`
  display: flex;
  padding-right: 8px;
`;

const MainNavigationStyled = styled(BaseNavigationBar)`
  z-index: 2;
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  max-width: ${web_max_width}px;
  margin: auto;
`;

const Title = styled.div`
  color: ${Gray_Light_900};
`;
