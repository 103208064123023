import { ComponentProps } from 'react';
import styled from '@emotion/styled';
import Link from 'next/link';
import { Gray_Light_400 } from 'styles';

const StyledAuthLink = styled.a<{ text_color: string }>`
  color: ${({ text_color }) => text_color};
  &:link,
  &:active,
  &:visited {
    color: ${({ text_color }) => text_color};
  }
`;

interface LinkProps extends Omit<ComponentProps<typeof StyledAuthLink>, 'as' | 'text_color'> {
  href: string;
  as?: string;
  scroll?: boolean;
  replace?: boolean;
  text_color?: string;
  onClick?: () => void;
}

/** 지그재그 웹 auth에 비밀번호 찾기 같은 링크이동 */
const AuthLink = ({
  className,
  href,
  as,
  replace,
  children,
  scroll,
  text_color = Gray_Light_400,
  onClick,
  ...props
}: LinkProps) => (
  <Link href={href} as={as} passHref scroll={scroll} replace={replace}>
    <StyledAuthLink className={className} text_color={text_color} onClick={onClick} {...props}>
      {children}
    </StyledAuthLink>
  </Link>
);
export default AuthLink;
