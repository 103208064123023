import type { MouseEvent } from 'react';
import { useTranslation } from 'react-i18next';
import {
  BottomNavigation,
  BottomNavigationLinkProps,
  IconHeartBold,
  IconHeartSolid,
  IconHomeBold,
  IconHomeSolid,
  IconMenuBold,
  IconMenuSolid,
  IconMypageBold,
  IconMypageSolid,
} from '@croquiscom-pvt/zds';
import Link from 'next/link';
import { useRouter } from 'next/router';

import { FixedWrapper } from '../component/Fixed';
import { useGNBConfig } from '../context/ConfigProvider';
import { createNavigationFromPath, GNBObjectID, useGNBUbl } from '../log/useGNBUbl';
import { useBottomTabBarLoginService } from '../service/useBottomTabBarLoginService';

/** @public */
export const GlobalNavigationContainer = () => {
  const { t } = useTranslation('common');
  const { asPath, query, route, pathname } = useRouter();
  const { path } = useGNBConfig();
  const { click } = useGNBUbl();
  const { goToLogin } = useBottomTabBarLoginService();
  const ublNavigation = createNavigationFromPath({ asPath, path, query });

  const items = [
    {
      id: 'home',
      title: t('홈'),
      href: path.home,
      icon: IconHomeBold,
      selectedIcon: IconHomeSolid,
    },
    {
      id: 'category',
      title: '카테고리',
      href: path.categories.root,
      icon: IconMenuBold,
      selectedIcon: IconMenuSolid,
    },
    {
      id: 'saved_product',
      title: '찜',
      href: path.picks.root,
      icon: IconHeartBold,
      selectedIcon: IconHeartSolid,
    },
    {
      id: 'my_page',
      title: t('마이페이지'),
      href: path.my_page.root,
      icon: IconMypageBold,
      selectedIcon: IconMypageSolid,
    },
  ];

  const handleClick = (id: string, href: string, event: MouseEvent<HTMLAnchorElement>) => {
    click({ object_id: id as GNBObjectID, navigation: ublNavigation });
    if (id === 'saved_product' || id === 'my_page') {
      goToLogin(event, href);
    }
  };

  return (
    <FixedWrapper>
      <BottomNavigation
        Link={LinkWrapper}
        items={items}
        selected={getSelectedTab(route, pathname)}
        onClick={handleClick}
      />
    </FixedWrapper>
  );

  function getSelectedTab(route: string, pathname: string) {
    if (route === path.home || route === path.root) {
      return 'home';
    }
    if (route.includes(path.categories.root)) {
      return 'category';
    }
    if (route.includes(path.picks.root)) {
      return 'saved_product';
    }
    const matchPaths = [path.orders, path.auth.root, path.my_page.root];
    if (matchPaths.some((path) => pathname.match(path))) {
      return 'my_page';
    }
    return '';
  }
};

function LinkWrapper(props: BottomNavigationLinkProps) {
  const { href, ...restProps } = props;
  return (
    <Link href={href}>
      <a {...restProps} />
    </Link>
  );
}
