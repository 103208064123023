import { QueryOptions } from 'react-query';

import config from 'util/config';

interface Os {
  android: string;
  ios: string;
}

export interface Metadata {
  kakao_connect_encourage_message: {
    my_page: string;
  };
  signup_event_message: {
    my_page: string;
    login_tooltip: string;
    login_page: string;
  };
  latest_version: Os;
  minimum_version: Os;
}

export const metadataQueryOption = (): QueryOptions<Metadata, unknown, Metadata, 'getMetadata'> => ({
  queryFn: async () => {
    const response = await fetch(`${config.apiConsumerBaseUrl}/metadata`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
      credentials: 'include',
    });
    if (!response.ok) {
      throw new Error('Metadata response was not ok');
    }
    const result = response.json();
    return result;
  },
  queryKey: 'getMetadata',
});
