export const IllustCompleted = ({ width = '100%', color, ...props }: JSX.IntrinsicElements['svg']) => (
  <svg width={width} viewBox='0 0 375 128' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      d='M188 98C206.778 98 222 82.7777 222 64C222 45.2223 206.778 30 188 30C169.222 30 154 45.2223 154 64C154 82.7777 169.222 98 188 98Z'
      fill={color ?? '#FF6CF5'}
    />
    <path
      d='M183.24 78.6745L170.307 66.0401L174.087 62.1777L183.186 71.0857L203.899 49.9785L207.72 53.8001L183.24 78.6745Z'
      fill='white'
    />
  </svg>
);
